<template>
  <div class="history-list-cell">
    <van-row @click="to(data.to)" class="card" type="flex" justify="left" align="center">
      <van-col span="6" class="progress" :style="{color: data.line ? '#5CA4F4' : 'black'}">
        {{ data.date }}
        <i v-if="data.line" class="line"></i>
      </van-col>
      <van-col span="12" offset="1">
        <van-row>
          <van-col span="24" class="text van-ellipsis title">{{ data.title }}</van-col>
        </van-row>
        <van-row>
          <van-col span="12" class="text van-ellipsis info">
            {{ data.date }}
          </van-col>
          <van-col offset="1" span="11" class="text van-ellipsis info">
            {{ data.user }}
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {

    }
  },
  methods: {
    to(to) {
      this.$router.push(to);
    }
  },
  props: {
    data: Object
  }
}
</script>
<style scoped>
.history-list-cell {
  background: #ffffff;
  margin-top: 4px;
}

.history-list-cell .card {
  padding-top: 5px;
}

.history-list-cell .progress {
  position: relative;
  font-weight: 700;
  font-size: 14px;
  color: #101010;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.history-list-cell .progress i.line:nth-last-of-type(1) {
  display: block;
  position: absolute;
  height: 30px;
  border-left: 1px solid;
  left: 50%;
  color: #5b6b73;
}


.history-list-cell .text {
  text-align: left;
}

.history-list-cell .title {
  font-weight: 400;
  font-size: 15px;
  color: #111111;
  font-style: normal;
  letter-spacing: 0;
  line-height: 27px;
  text-decoration: none;
}

.history-list-cell .info {
  font-weight: 400;
  font-size: 12px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.history-list-cell .icon {
  line-height: 24px;
}
</style>
