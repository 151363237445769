<template>
  <div class="early-warning-history-list">
    <van-row>
      <van-col>
        <div class="baseInfo">
          历史记录
        </div>
      </van-col>
    </van-row>
    <div ref="fresh" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
      <div v-if="list.length<=0">
        <van-empty image="error" description="没有数据"/>
      </div>
      <div v-else>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text=""
                    @load="onLoad"
                >
        <history-list-cell v-for="item in list" v-bind:key="item.id" :data="item"/>
                </van-list>
      </div>
    </div>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import HistoryListCell from "@/components/HistoryListCell/index";

export default {
  name: "EarlyWarningHistoryList",
  components: {CrmTabBar, HistoryListCell},
  data() {
    return {
      //列表高度
      freshHeight: 0,
      //下拉标识
      isLoading: false,
      //上拉标识
      loading: false,
      //上拉加载完成
      finished: true,
      list: [
        {id: '0', title: '标题一', date: '2022-12-12', user: '刘小恺', line: true,},
        {id: '1', title: '标题二', date: '2022-09-12', user: '张航', line: true,},
        {id: '2', title: '标题三', date: '2022-06-12', user: '刘小恺', line: true,},
        {id: '3', title: '标题四', date: '2022-03-12', user: '刘小恺', line: false,}
      ]
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    console.log(this.freshHeight)
  },
  methods: {

    // 上拉加载
    onLoad() {

    },
  }
}
</script>

<style scoped>

.baseInfo {
  font-size: 10px;
  color: #BAB8B7;
  padding: 8px;
}

</style>